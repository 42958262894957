//import { axios } from 'vue/types/umd';
import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { privileges } from '@/services/privileges'
import { defineStore } from 'pinia'
export const useReportingObligationsStore = defineStore('reportingObligationsStore', {
  state: () => ({
    reportingObligations: [] as any[]
  }),
  actions: {
    async fetchReportingObligations() {
      /* check privileges, call of unprivileged api leads to an error */
      if (this.reportingObligations.length === 0 && privileges.has({ path: '/reportingObligations', permission: 'read' })) {
        try {
          const result = await app.axios.get(apiURL + '/reportingObligations')
          this.reportingObligations = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
